<template>
    <div class="history_info">
        <van-sticky :offset-top="0" class="top_info">
            <div class="name_info">
                <div class="name_num">
                    <span class="left">{{name}}</span>
                </div>
                <div class="sex_age_phone">
                    <span>{{sex}} . {{age}}岁</span>
                    <span v-if="phone">{{phone}}</span>
                </div>
            </div>
        </van-sticky>

        <van-tabs
                v-model="index" :offset-top="107"
                @change="changeTab"
                color="#E69F12"
                title-active-color="#E69F12"
                sticky
                swipeable>

            <van-tab title="检查历史" name="0" >
                <div class="treat_main_list" :class="{'bai': tcount <= 0}" >
                    <van-list
                            v-model="tloading"
                            :finished="tfinished"
                            @load="treatHistoryQry"
                            :offset="30"
                            :immediate-check="false"
                            v-if="tlist.length>0"
                            finished-text="加载完成">
                        <div class="one" v-for="item in tlist" :key="item.serviceid">
                            <div class="project_name">
                                <div class="left">{{item.projectname}}</div>
                                <div class="right" :class="'status'+item.execstatuscode">{{item.execstatusname}}</div>
                            </div>
                            <div class="title_top">
                                <div class="left">预约时间</div>
                                <div class="right">{{item.appointmenttime}}</div>
                            </div>
                            <div class="title_top">
                                <div class="left">执行时间</div>
                                <div class="right">{{item.exectime}}</div>
                            </div>
                            <div class="title_top">
                                <div class="left">执行医生</div>
                                <div class="right">{{item.execdoctor}}</div>
                            </div>
                            <div class="title_top" v-if="item.consume">
                                <div class="left">消费患者</div>
                                <div class="right status0">{{item.consume}}</div>
                            </div>
                            <div class="title_top" v-if="item.useuserid !== item.userid || item.usecustomerid !== item.customerid">
                                <div class="left">扣费账号</div>
                                <div class="right status0">{{item.feeaccount}}</div>
                            </div>
                            <div class="title_top" v-if="item.projectcode != 'C0002'">
                                <div class="left">检查备注</div>
                                <div class="right">{{item.remark || '--'}}</div>
                            </div>
                            <div class="title_top">
                                <div class="left">报告状态</div>
                                <div class="right">{{item.reportstatusname}}</div>
                            </div>
                            <div class="title_top" v-if="item.reportstatus === 2">
                                <div class="left">报告医生</div>
                                <div class="right">{{item.reportdoctor}}</div>
                            </div>
                            <div class="title_top" v-if="item.reportstatus === 2">
                                <div class="left">报告时间</div>
                                <div class="right">{{item.reporttime}}</div>
                            </div>
                            <div v-if="item.reportstatus === 2 && (item.projectcode === 'C0001' || item.projectcode === 'C0002')" class="report_btn">
                                <button v-if="item.projectcode === 'C0001'" @click="habitusReport(item.serviceid, item.paystatus)">查看报告</button>
                                <button v-else @click="report(item.serviceid)">查看报告</button>
                            </div>
                        </div>
                    </van-list>
                    <div class="has_no_data" v-else>
                        无检查历史数据
                    </div>
                </div>
            </van-tab>

            <van-tab  title="开单历史" name="1">
                <div class="treat_main_list" :class="{'bai': ocount <= 0}">
                    <van-list
                            v-model="oloading"
                            :finished="ofinished"
                            @load="openHistoryQry"
                            :offset="30"
                            :immediate-check="false"
                            v-if="olist.length>0"
                            finished-text="加载完成">
                        <div class="one" v-for="(item, i) in olist" :key="i"  @click="detail(item.id)">
                            <div class="prescription_info">
                                <div class="left">
                                    <div class="type">{{item.seetype}}</div>
                                    <div class="name">{{item.name}}</div>
                                </div>
                                <div class="right">{{item.seetime}}</div>
                            </div>
                            <div class="sex_age_phone">
                                <span>{{item.sex}} . {{item.age}}岁</span>
                                <span v-if="item.phone">{{item.phone}}</span>
                            </div>
                            <div class="title_top">
                                <div class="left">主诉</div>
                                <div class="right">{{item.complaint || '--'}}</div>
                            </div>
                            <div class="title_top">
                                <div class="left">中医诊断</div>
                                <div class="right">{{item.diagnose || '--'}}</div>
                            </div>
                            <div class="title_top">
                                <div class="left">检查单</div>
                                <div class="right">{{item.treatinfo || '--'}}</div>
                            </div>
                        </div>
                    </van-list>
                    <div class="has_no_data" v-else>
                        无开单历史数据
                    </div>
                </div>
            </van-tab>

            <van-tab  title="剩余次数" name="2">
                <div class="surplus_main_list" :class="{'bai': scount <= 0}" v-if="slist.length>0">
                    <div class="two" v-for="(item, i) in slist" :key="i">
                        <div class="name">{{item.projectname}}</div>
                        <div class="num">
                            <div class="left">剩余次数（次）</div>
                            <div class="right">{{item.surplus}}</div>
                        </div>
                    </div>
                </div>
                <div class="has_no_data" v-else>
                    无剩余次数
                </div>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
    import { wxopenhistoryqry,wxcheckhistoryqry,wxsurplusqry } from '@/api/medical'
    import { mapGetters } from 'vuex'
    import { Toast } from 'vant';
    // import Vue from 'vue';

    export default {
        computed: {
            ...mapGetters([
                'username','isrefresh'
            ]),
        },
        data() {
            return {
                index: '0',
                olist: [],
                // 无限加载
                oloading: false,
                ofinished: false,
                ocount: 0,
                opage: 1,
                oisload: false,
                tlist: [],
                // 无限加载
                tloading: false,
                tfinished: false,
                tcount: 0,
                tpage: 1,
                tisload: false,
                slist: [],
                // 无限加载
                sloading: false,
                sfinished: false,
                scount: 0,
                sisload: false,
                name:'',
                sex:'',
                age:'',
                phone:'',
                userid:'',
                customerid:'',
            }
        },
        // mounted () {
        //     this.$store.dispatch('hideOrShowNav', false)
        //     this.name = this.$route.query.name || ''
        //     this.sex = this.$route.query.sex || ''
        //     this.age = this.$route.query.age || ''
        //     this.phone = this.$route.query.phone || ''
        //     this.userid = this.$route.query.userid || ''
        //     this.customerid = this.$route.query.customerid || ''
            
        //     this.treatHistoryQry()
        // },
        activated() {

            this.$store.dispatch('hideOrShowNav', false)
            console.log(this.isrefresh,'this.isrefresh')
            if(this.isrefresh == '1') {
                this.name = this.$route.query.name || ''
                this.sex = this.$route.query.sex || ''
                this.age = this.$route.query.age || ''
                this.phone = this.$route.query.phone || ''
                this.userid = this.$route.query.userid || ''
                this.customerid = this.$route.query.customerid || ''
                this.index = '0'
                this.tisload = false;
                this.tfinished = false;
                this.tpage = 1;
                this.tlist = [];
                this.oisload = false;
                this.ofinished = false;
                this.opage = 1;
                this.olist = [];
                this.sisload = false;
                this.sfinished = false;
                this.spage = 1;
                this.slist = [];
                this.treatHistoryQry()
            }
        },
        beforeRouteLeave(to, from, next){
            this.$store.dispatch('changeIsRefresh', '0')
            next()
        },
        methods: {
            changeTab(){
                if(this.index === '0') {
                    if(this.tisload) {
                        return
                    } else {
                        this.treatHistoryQry()
                    }

                }
                if(this.index === '1') {
                    if(this.oisload) {
                        return
                    } else {
                        this.openHistoryQry()
                    }
                }
                if(this.index === '2') {
                    if(this.sisload) {
                        return;
                    } else {
                        this.surplusQry()
                    }
                }
            },

            //开单历史查询
            openHistoryQry() {
                Toast({
                    message: '加载中...',
                    type: 'loading',
                    duration: 0
                })
                let data = {
                    username: this.username,
                    userid: this.userid,
                    customerid: this.customerid,
                    qrypage: this.opage,
                    qrytype: "2"
                }
                wxopenhistoryqry(data).then(response => {
                    if(Toast){
                        Toast.clear()
                    }
                    if(response.response_body && response.response_body.olist){
                        this.ocount = parseInt(response.response_body.num)
                        this.olist = this.olist.concat(response.response_body.olist)
                        this.ofinished = (this.opage >= response.response_body.pages);
                        this.opage = parseInt(this.opage)+1
                    }
                    this.oisload = true;
                    // 加载状态结束
                    this.oloading = false;
                })
            },
            //检查历史查询
            treatHistoryQry() {
                if(this.tfinished) {
                    return
                }
                Toast({
                    message: '加载中...',
                    type: 'loading',
                    duration: 0
                })
                let data = {
                    username: this.username,
                    userid: this.userid,
                    customerid: this.customerid,
                    qrypage: this.tpage
                }
                wxcheckhistoryqry(data).then(response => {
                    if(Toast){
                        Toast.clear()
                    }
            
                    if(response.response_body && response.response_body.tlist){
                        this.tcount = parseInt(response.response_body.num)
                        this.tlist = this.tlist.concat(response.response_body.tlist)
                        this.tfinished = (this.tpage >= response.response_body.pages);
                        this.tpage = parseInt(this.tpage)+1
                    }
                    this.tisload = true
                    // 加载状态结束
                    this.tloading = false;
                })
            },
            //剩余次数查询
            surplusQry() {
                Toast({
                    message: '加载中...',
                    type: 'loading',
                    duration: 0
                })
                let data = {
                    username: this.username,
                    userid: this.userid,
                    customerid: this.customerid,
                    qrytype: "2"
                }
                wxsurplusqry(data).then(response => {
                    if(Toast){
                        Toast.clear()
                    }
                    if(response.response_body && response.response_body.slist){
                        this.scount = parseInt(response.response_body.num)
                        this.slist = this.slist.concat(response.response_body.slist)
                        this.sfinished = true;
                    }
                    this.sisload = true
                    // 加载状态结束
                    this.sloading = false;
                })
            },

            detail(id) {
                this.$store.dispatch('changeIsRefresh', '1')
                this.$router.push({
                    path: '/medicalDetail',
                    query: {
                        userid: this.userid,
                        customerid: this.customerid,
                        id: id,
                        type: '0005'
                    }
                })
            },
            //五行体质报告查看
            habitusReport(serviceid, paystatus) {
                this.$router.push({
                    path: '/habitusDetail',
                    query: {
                        serviceid: serviceid,
                        paystatus: paystatus
                    }
                })
            },
            //红外报告查看
            report(serviceid) {
                this.$router.push({
                    path: '/infraredDetail',
                    query: {
                        serviceid: serviceid
                    }
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
    .van-info{
        top: 2px;
        right: -8px;
    }
    .van-cell{
        background-color: #F8F7FC;
        border-radius: 0.50667rem;
    }
    .bai {
        background-color: #ffffff;
    }
</style>